<template>
  <div class="lottery-history">
    <div class="header">
      <div class="title-box">
        <p class="title">七码图表</p>
        <intro-default cont=""></intro-default>
      </div>
      <div class="search-box">
        <div class="switch_fn_bt" @click="show">{{ show_arr[typeIndex] ? '隐藏' : '展开' }}结果</div>
        <select-user type="1" @change="change_fn"></select-user>
      </div>
    </div>
    <div class="lz_cont">
      <ul class="switch">
        <li :class="{ active: i === typeIndex }" v-for="(type, i) in types" :key="type.value" @click="typeAction(i)">{{
          type.label }}</li>
        <span class="switch_space"></span>
      </ul>
      <div class="table" id="table_map">
        <el-table :data="rows" border style="width: 100%" header-cell-class-name="table-header-cell"
          cell-class-name="table-cell" row-class-name="table-row" :cell-style="tableCellStyle" size="small" row-key="id"
          ref="table" id="table">
          <!-- <el-table-column v-if="show_arr[typeIndex]" prop="opentime" label="开奖时间" :min-width="typeIndex > 0 ? 96 : 145" align="center">
          </el-table-column> -->
          <el-table-column v-if="show_arr[typeIndex]" prop="expect" label="期数" :min-width="typeIndex > 0 ? 96 : 76"
            align="center">
          </el-table-column>
          <el-table-column v-show="typeIndex > 0" v-for="(title, i) in opencodeTitles" :key="title" :label="title"
            align="center" min-width="38">
            <template #default="scope">
              {{ scope.row.opencode[i] }}
            </template>
          </el-table-column>
          <el-table-column v-for="(title, i) in titles" :key="title" :label="title" align="center"
            :min-width="typeIndex > 0 ? 40 : 24">
            <template #default="scope">
              {{ typeIndex !== 3 ? scope.row.list[i] : scope.row.list[title] }}
              <!-- <i v-show="typeIndex == 0" :class="{line_p: i == 10 || i == 20 || i == 30 || i == 40}"></i> -->
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, DatePicker, Popover, Loading } from 'element-ui'
import moment from 'moment'

const kBallColors = {
  red: [1, 2, 7, 8, 12, 13, 18, 19, 23, 24, 29, 30, 34, 35, 40, 45, 46],
  blue: [3, 4, 9, 10, 14, 15, 20, 25, 26, 31, 36, 37, 41, 42, 47, 48],
  green: [5, 6, 11, 16, 17, 21, 22, 27, 28, 32, 33, 38, 39, 43, 44, 49]
}
const kRedBallColor = '#f02f22'
const kBlueBallColor = '#0e77c8'
const kGreenBallColor = 'green'

export default {
  name: 'LotteryHistory',
  props: {
    code: String
  },
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    [Popover.name]: Popover
  },
  data() {
    return {
      per_page: 10,
      types: [
        {
          label: '号码',
          value: 'number'
        },
        {
          label: '生肖',
          value: 'zodiac'
        },
        {
          label: '尾数',
          value: 'tail'
        },
        {
          label: '波色',
          value: 'chart_bose'
        }
      ],
      typeIndex: 0,
      opencodeTitles: [],
      date: Date.now(),
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      titles: null,
      rows: null,
      data: null,
      loading: null,
      show_arr: [false, false, false, false]
    }
  },
  computed: {
    type() {
      return this.types[this.typeIndex].value
    }
  },
  watch: {
    code: function (val, oldVal) {
      this.per_page = 10
      this.requestGetHistory()
    },
    date: function (val, oldVal) {
      this.requestGetHistory()
    }
  },
  mounted() {
    this.requestGetHistory()
  },
  methods: {
    change_fn(value) {
      this.per_page = value
      this.requestGetHistory()
    },
    show() {
      const val = this.show_arr[this.typeIndex]
      this.opencodeTitles = !val ? ['正一', '正二', '正三', '正四', '正五', '正六', '特码'] : []
      this.show_arr.splice(this.typeIndex, 1, !val)
    },
    refresh() {
      this.requestGetHistory()
    },
    typeAction(index) {
      this.opencodeTitles = this.show_arr[index] ? ['正一', '正二', '正三', '正四', '正五', '正六', '特码'] : []
      this.typeIndex = index
      this.requestGetHistory()
    },
    tableCellStyle({ row, column, rowIndex, columnIndex }) {
      if (columnIndex >= 1 && columnIndex <= 7 && this.show_arr[this.typeIndex]) {
        const i = columnIndex - 1
        const number = parseInt(row.opencode[i])
        if (kBallColors.red.indexOf(number) !== -1) {
          return { color: kRedBallColor }
        }
        if (kBallColors.blue.indexOf(number) !== -1) {
          return { color: kBlueBallColor }
        }
        if (kBallColors.green.indexOf(number) !== -1) {
          return { color: kGreenBallColor }
        }
        return null
      }
      const i = this.show_arr[this.typeIndex] ? columnIndex - 8 : columnIndex
      const value = row.list[i]
      const style = {}
      if (typeof value === 'string') {
        if (this.type === 'number') {
          const number = parseInt(value)
          if (kBallColors.red.indexOf(number) !== -1) {
            return Object.assign(style, {
              position: 'relative',
              color: '#282828',
              fontWeight: 'bold',
              fontSize: '12px',
              background: 'url(' + require('@assets/pc/lhc1.png') + ') center center / 20px 20px no-repeat'
            })
          }
          if (kBallColors.blue.indexOf(number) !== -1) {
            return Object.assign(style, {
              position: 'relative',
              color: '#282828',
              fontWeight: 'bold',
              fontSize: '12px',
              background: 'url(' + require('@assets/pc/lhc2.png') + ') center center / 20px 20px no-repeat'
            })
          }
          if (kBallColors.green.indexOf(number) !== -1) {
            return Object.assign(style, {
              position: 'relative',
              color: '#282828',
              fontWeight: 'bold',
              fontSize: '12px',
              background: 'url(' + require('@assets/pc/lhc3.png') + ') center center / 20px 20px no-repeat'
            })
          }
          return Object.assign(style, { position: 'relative', color: '#A0A0A0' })
        }
        return Object.assign(style, {
          position: 'relative',
          color: kRedBallColor,
          fontWeight: 'bold'
        })
      }
      return Object.assign(style, { position: 'relative', color: '#A0A0A0' })
    },
    requestGetHistory() {
      this.rows = []
      this.showTableLoading()
      this.$api.chart(this.code, this.type, this.per_page).then((res) => {
        this.data = res.data
        this.rows = this.data?.rows || []
        this.titles = this.data?.titles || []
        this.hideTableLoading()
      }).catch((err) => {
        this.hideTableLoading()
        this.$message.error(err)
      })
    },
    showTableLoading() {
      if (this.loading) return
      this.loading = Loading.service({
        target: '#table',
        fullscreen: false,
        background: 'rgba(0, 0, 0, 0)'
      })
    },
    hideTableLoading() {
      this.$nextTick(() => {
        this.loading.close()
        this.loading = null
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@scss/pc/variables";

.lottery-history {
  >.header {
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    border-bottom: thin $color-border solid;

    >.title-box {
      display: flex;
      align-items: center;
      height: 49px;

      >.title {
        font-size: 20px;
        font-weight: 400;
        color: #222;
      }
    }

    >.search-box {
      display: flex;
      align-items: center;

      >.title {
        margin-right: 15px;
        font-size: 14px;
        color: #222;
      }

      >.icon-search {
        margin-left: 10px;
        width: 48px;
        height: 24px;
        display: block;
      }
    }
  }

  .lz_cont {
    padding: 18px;
    padding-top: 30px;
    padding-bottom: 0;
    box-sizing: border-box;
  }

  .switch {
    list-style: none;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    margin-bottom: 26px;

    >li {
      margin-right: 10px;
      cursor: pointer;
      list-style: none;
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #222;
      height: 26px;
      border-radius: 50px;

      &.active {
        color: #FA3E3E;
        background-color: #FEEBEB;
      }
    }
  }
}

.sum-tip {
  padding: 12px;

  >.title {
    font-size: 14px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.8);
  }

  >.content {
    margin-top: 8px;
    line-height: 20px;
    font-size: 12px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
  }
}

.line_p {
  width: 0.125rem;
  background-color: #aaa;
  position: absolute;
  top: 0;
  bottom: -0.0625rem;
  right: -0.125rem;
  z-index: 100;
}

.switch_fn_bt {
  margin-right: 20px;
  width: 80px;
  height: 24px;
  border-radius: 4px;
  line-height: 24px;
  font-size: 14px;
  text-align: center;
  background-color: #F9F9F9;
  color: #222;
  border: 1px solid #E3E3E6;
  cursor: pointer;
}
</style>

<style lang="scss">
#table_map .el-table .cell {
  white-space: nowrap;
  padding: 0 !important;
}

.lottery-history .table {
  margin-top: 20px;
}

.lottery-history .table .table-header-cell {
  background-color: #f9f9f9;
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
}

.lottery-history .table .table-cell {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  padding: 0;
  height: 40px;
}

.lottery-history .table .table-row:hover>td,
.lottery-history .table .table-row .current-row>td {
  background-color: rgba(255, 204, 170, 0.4);
}
</style>
