<template>
  <div class="lottery-trend-combination">
    <div class="header">
      <div class="title-box">
        <p class="title">组合走势</p>
        <intro-default cont="该走势图显示出每个位置上开出大双（6、8、10）、小双（2、4）、大单（7、8）、小单（1、3、5）的趋势。"></intro-default>
      </div>
      <div class="search-box">
        <div class="switch_fn_bt" @click="rlt_show">{{ is_rlt ? '隐藏' : '展开' }}结果</div>
        <select-user type="1" @change="page_change"></select-user>
      </div>
    </div>
    <div class="lz_cont">
      <ul class="switch">
        <li :class="{ active: indexForSelectRank === i }" v-for="(name, i) in rankNames" :key="name"
          @click="indexForSelectRank = i">{{ name }}</li>
      </ul>
      <div class="table" id="table">
        <!-- :width="tableWidth" -->
        <table cellpadding="0" cellspacing="0" class="table-data" width="100%" ref="table">
          <thead>
            <tr>
              <th :width="expectWidth + 20" rowspan="2" colspan="1">开奖时间</th>
              <th :width="expectWidth" rowspan="2" colspan="1">期号</th>
              <th v-if="is_rlt" :width="getOpenNumberW()" rowspan="2" colspan="1">开奖号码</th>
              <th v-for="(item, key) in head_l[type]" :key="new Date().getTime() + Math.random() * 10 + '' + key"
                rowspan="1" :colspan="item.colspan">{{ item.label }}</th>
            </tr>
            <tr>
              <th v-for="(item, key) in head_list[type]" :key="new Date().getTime() + Math.random() * 10 + '' + key"
                :width="codeWidth" colspan="1" rowspan="1">{{ item }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row) in tableData" :key="new Date().getTime() + Math.random() * 10 + '' + row.id" height="37">
              <td colspan="1" rowspan="1">{{ row.opentime }}</td>
              <td colspan="1" rowspan="1">{{ row.expect }}</td>
              <td v-if="is_rlt" colspan="1" rowspan="1">
                <div class="opencode">
                  <template v-for="(number, i) in row.rlt">
                    <number :value="number" :key="new Date().getTime() + Math.random() * 10 + '' + number"
                      :lottery-code="code" :lhc_icon="row.rlt.length === 7 && i == 6"></number>
                  </template>
                </div>
              </td>
              <td colspan="1" rowspan="1" v-for="(number, i) in row.row"
                :key="new Date().getTime() + Math.random() * 10 + '' + i">
                <span v-if="number === row.opencode" class="hot" ref="hots">
                  <number v-if="code != 'dongganpuke'" :value="number" :lottery-code="code"></number>
                  <span v-else class="hot_qiu">{{ number }}</span>
                </span>
                <span v-else>{{ number }}</span>
              </td>
            </tr>
          </tbody>
        </table>
        <trend-line-canvas v-for="obj in lineObjects" :key="obj.id" :width="obj.width" :height="obj.height"
          :start="obj.start" :end="obj.end" :color="obj.color" :line-width="obj.lineWidth" :top="obj.top"
          :left="obj.left"></trend-line-canvas>
      </div>
    </div>
  </div>
</template>

<script>
import { Loading, Select, Option } from 'element-ui'
import TrendLineCanvas from '@components/pc/TrendLineCanvas'
import randomString from '@plugins/random'
import Number from '@components/pc/Number'

export default {
  name: 'LotteryTrendCombination',
  props: {
    code: String
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [TrendLineCanvas.name]: TrendLineCanvas,
    [Number.name]: Number
  },
  data() {
    return {
      per_page: 10,
      is_rlt: true,
      expectWidth: 150,
      opencodeWidth: 240,
      codeWidth: 40,
      codeHeight: 37,
      data: null,
      loading: null,
      canvases: [],
      options: [
        {
          label: '40期',
          value: 30
        },
        {
          label: '90期',
          value: 90
        },
        {
          label: '今日所有',
          value: null
        }
      ],
      page: null,
      lineObjects: [],
      indexForSelectRank: 0,
      head_l: [
        [
          { label: '小单', colspan: 3 },
          { label: '小双', colspan: 2 },
          { label: '大单', colspan: 3 },
          { label: '大双', colspan: 2 }
        ],
        [
          { label: '小单', colspan: 5 },
          { label: '小双', colspan: 5 },
          { label: '大单', colspan: 5 },
          { label: '大双', colspan: 5 }
        ],
        [
          { label: '小双', colspan: 2 },
          { label: '小单', colspan: 3 },
          { label: '大双', colspan: 3 },
          { label: '大单', colspan: 2 }
        ],
        [
          { label: '小双', colspan: 3 },
          { label: '小单', colspan: 2 },
          { label: '大双', colspan: 2 },
          { label: '大单', colspan: 3 }
        ]
      ],
      head_list: [
        [1, 3, 5, 2, 4, 7, 9, 6, 8, 10],
        [1, 3, 5, 7, 9, 2, 4, 6, 8, 10, 11, 13, 15, 17, 19, 12, 14, 16, 18, 20],
        [2, 4, 1, 3, 5, 6, 8, 10, 7, 9],
        [0, 2, 4, 1, 3, 6, 8, 5, 7, 9]
      ],
      type: 0
    }
  },
  computed: {
    tableWidth() {
      const length = this.rankNames.length
      return this.expectWidth + this.opencodeWidth + this.codeWidth * length * length
    },
    tableHeight() {
      return this.codeHeight * (2 + this.data.length)
    },
    tableData() {
      if (!this.data) return []
      const array = []
      for (const { id, opentime, expect, arr, list, opencode } of this.data.list) {
        array.push({
          id,
          opentime,
          expect,
          opencode: arr[this.indexForSelectRank],
          row: list[this.indexForSelectRank],
          rlt: opencode.toString().split(',')
        })
      }
      return array
    },
    rankNames() {
      if (!this.data) return []
      return this.data.titles || []
    }
  },
  watch: {
    code: function (val, oldVal) {
      this.per_page = 10
      this.requestGetHistory()
    },
    page: function (val, oldVal) {
      this.requestGetHistory()
    },
    indexForSelectRank: function (val, oldVal) {
      this.$nextTick(() => {
        this.drawLine()
      })
    }
  },
  mounted() {
    this.requestGetHistory()
  },
  methods: {
    getOpenNumberW() {
      const code = this.code
      if (code === 'xyft' || code === 'donggansaiche' || code === 'jisupk10' || code === 'azxy10' || code === 'sbspeedy10' || code === 'sgairship') {
        return 360
      } else if (code === 'gdklsf' || code === 'azxy8' || code === 'jisukl10' || code === 'donggansaima') {
        return 290
      }

      return 220
    },
    page_change(value) {
      this.per_page = value
      this.requestGetHistory()
    },
    rlt_show() {
      this.is_rlt = !this.is_rlt
      this.$nextTick(() => {
        this.drawLine()
      })
    },
    get_type() {
      const obj = {
        'xyft': 0,
        'donggansaiche': 0,
        'jisupk10': 0,
        'azxy10': 0,
        'jisupk10168': 0,
        'azxy10168': 0,
        'sbspeedy10': 0,
        'sgairship': 0,
        'azxy8': 1,
        'jisukl10': 1,
        'azxy8168': 1,
        'jisukl10168': 1,
        'gdklsf': 1,
        'donggansaima': 1,
        'donggan61': 6,
        'amlhc': 6,
        'twlhc': 6,
        'hk6': 6,
        'happy61': 6,
        'dongganshengxiao': 5,
        'azxy5': 2,
        'azxy5168': 2,
        'txffc': 2,
        'jisussc': 2,
        'jisussc168': 2,
        'dongganwuxing': 2,
        'sg5d': 2,
        'dongganpuke': 3,
        'donggansanbao': 4,
        'speedy3': 4,
        'sgquick3': 4,
        'pcdd': 3
      }
      this.type = obj[this.code] || 0
    },
    refresh() {
      this.requestGetHistory()
    },
    hideTableHeader({ row, column, rowIndex, columnIndex }) {
      return {}
    },
    tableCellStyle({ row, column, rowIndex, columnIndex }) {
      return {}
    },
    requestGetHistory() {
      this.showTableLoading()
      this.$api.combinationTrend(this.code, this.per_page).then((res) => {
        this.get_type()
        this.data = res.data
        this.hideTableLoading()
        this.$nextTick(() => {
          this.drawLine()
        })
      }).catch((err) => {
        this.hideTableLoading()
        this.$message.error(err)
      })
    },
    showTableLoading() {
      if (this.loading) return
      this.loading = Loading.service({
        target: '#table',
        fullscreen: false,
        background: 'rgba(0, 0, 0, 0)'
      })
    },
    hideTableLoading() {
      this.$nextTick(() => {
        this.loading.close()
        this.loading = null
      })
    },
    drawLine() {
      this.lineObjects = []

      const lineWidth = 1

      const hots = this.$refs.hots.sort((a, b) => {
        return a.getBoundingClientRect().y - b.getBoundingClientRect().y
      })

      const tableRect = this.$refs.table.getBoundingClientRect()

      var lastCenter = null

      for (let i = 0; i < hots.length; i++) {
        const hot = hots[i]
        const hotRect = hot.getBoundingClientRect()
        const center = {
          x: hotRect.x - tableRect.x + hotRect.width / 2,
          y: hotRect.y - tableRect.y + hotRect.height / 2
        }

        if (!lastCenter) {
          lastCenter = center
          continue
        }

        var obj = {
          lineWidth,
          color: '#1D8DDE',
          id: randomString(7)
        }

        const canvasWidth = Math.abs(lastCenter.x - center.x)
        const canvasHeight = Math.abs(lastCenter.y - center.y)

        obj.width = canvasWidth
        obj.height = canvasHeight

        if (lastCenter.x > center.x) {
          obj.start = {
            x: canvasWidth,
            y: 0
          }
          obj.end = {
            x: 0,
            y: canvasHeight
          }

          obj.left = center.x
          obj.top = lastCenter.y
        } else if (lastCenter.x < center.x) {
          obj.start = {
            x: 0,
            y: 0
          }
          obj.end = {
            x: canvasWidth,
            y: canvasHeight
          }

          obj.left = lastCenter.x
          obj.top = lastCenter.y
        } else {
          obj.start = {
            x: lineWidth / 2,
            y: 0
          }
          obj.end = {
            x: lineWidth / 2,
            y: canvasHeight
          }

          obj.left = (lastCenter.x - lineWidth / 2)
          obj.top = lastCenter.y
          obj.width = lineWidth
        }

        this.lineObjects.push(obj)

        lastCenter = center
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@scss/pc/variables';

.lottery-trend-combination {
  >.header {
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    border-bottom: thin $color-border solid;

    >.title-box {
      display: flex;
      align-items: center;
      height: 49px;

      >.title {
        font-size: 20px;
        font-weight: 400;
        color: #222;
      }
    }

    >.search-box {
      display: flex;
      align-items: center;

      >.title {
        margin-right: 15px;
        font-size: 14px;
        color: #222;
      }

      >.icon-search {
        margin-left: 10px;
        width: 48px;
        height: 24px;
        display: block;
      }
    }
  }

  .lz_cont {
    padding: 20px;
    padding-top: 30px;
    padding-bottom: 0;
    box-sizing: border-box;
  }

  .switch {
    list-style: none;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    margin-bottom: 26px;

    >li {
      margin-right: 10px;
      cursor: pointer;
      list-style: none;
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #222;
      height: 26px;
      border-radius: 50px;

      &.active {
        color: #FA3E3E;
        background-color: #FEEBEB;
      }
    }
  }

  .table {
    position: relative;

    >.table-data {
      table-layout: fixed;
      word-break: break-all;
      word-wrap: break-word;
      border-collapse: separate;
      border: 1px solid #d6d6d6;
      border-right: none;
      border-bottom: none;

      .el-table thead {
        color: #909399;
        font-weight: bold;
      }

      td,
      th {
        min-width: 0;
        box-sizing: border-box;
        text-overflow: ellipsis;
        vertical-align: middle;
        position: relative;
        text-align: center;
        border-bottom: 1px solid #d6d6d6;
        border-right: 1px solid #d6d6d6;
        font-size: 14px;
      }

      th {
        height: 37px;
        font-weight: bold;
        background-color: #f9f9f9;
        color: rgba(0, 0, 0, 0.5);
      }

      td {
        color: rgba(0, 0, 0, 0.8);
      }
    }

    .opencode {
      color: #fa3e3e;

      span+span {
        margin-left: 5px;
      }
    }

    .hot {
      position: relative;
      z-index: 100;

      .hot_qiu {
        display: inline-block;
        color: white;
        background: #07A6F8;
        width: 24px;
        height: 24px;
        line-height: 24px;
        font-size: 14px;
        text-align: center;
        border-radius: 50%;
      }
    }
  }
}

.sum-tip {
  padding: 12px;

  >.title {
    font-size: 14px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.8);
  }

  >.content {
    margin-top: 8px;
    line-height: 20px;
    font-size: 12px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
  }
}

.switch_fn_bt {
  margin-right: 20px;
  width: 80px;
  height: 24px;
  border-radius: 4px;
  line-height: 24px;
  font-size: 14px;
  text-align: center;
  background-color: #F9F9F9;
  color: #222;
  border: 1px solid #E3E3E6;
  cursor: pointer;
}
</style>

<style lang="scss">
.hot {
  .lottery-number {
    width: 24px !important;
    height: 24px !important;
    line-height: 24px !important;
  }
}
</style>
