<template>
  <div class="n-lottery">
    <div class="full_header container" v-if="lottery">
      <div class="header">
        <div class="lottery">
          <img :src="lottery.lottery.http_logo" class="icon">
          <div class="content">
            <div class="text">
              <p class="lot_title">{{ lottery.lottery.name }}</p>
              <!-- <p>已开<span>{{ lottery.lottery.open }}</span>期</p>
              <p>剩余<span>{{ lottery.lottery.not_open }}</span>期</p> -->
            </div>
            <div class="numbers">
              <p style="font-size: 16px; color: #222;margin-right: 16px;margin-bottom: 8px;">第<span>{{
                lottery.lottery.expect }}</span>期结果</p>
              <div>
                <template v-for="(number, i) in lottery.lottery.opencode_arr">
                  <!-- v-if="lottery.lottery.next_time > 0" -->
                  <number data-aos="flip-right" data-aos-duration="600" data-aos-easing="ease-in-out-cubic"
                    :value="number" size="big" :key="`${i}-${number}`" :lottery-code="lottery.lottery.code"
                    :lhc_icon="lottery.lottery.opencode_arr.length === 7 && i == 6"></number>
                  <!-- <number v-else class="transparent60" :class="{transparent100: is_tra === i}" :value="number" size="big" :key="`${i}-${number}`" :lottery-code="lottery.lottery.code" :lhc_icon="lottery.lottery.opencode_arr.length === 7 && i == 6"></number> -->
                </template>
              </div>
            </div>
            <!-- <p class="detail">新手必看：<span>怎么玩？</span></p> -->
          </div>
        </div>
        <div class="count-down">

          <div class="lot_text">
            <p></p>
            <div>
              <!-- <img src="@assets/pc/cnm_ui.png"/> -->
              距{{ lottery.lottery.next_expect }}期开奖
            </div>
          </div>
          <div class="timer" v-if="lottery.lottery.next_time > 0">
            <div v-if="dayTime(true) + dayTime() > 0">
              <div class="t">
                <div v-if="t_arr[7]" data-aos="flip-down" data-aos-duration="700" data-aos-easing="ease-in-out-cubic">{{
                  dayTime(true) }}</div>
              </div>
              <div class="t">
                <div v-if="t_arr[8]" data-aos="flip-down" data-aos-duration="700" data-aos-easing="ease-in-out-cubic">{{
                  dayTime() }}</div>
              </div>
              <p>天</p>
            </div>
            <div v-if="hourTime(true) + hourTime() > 0">
              <div class="t">
                <div v-if="t_arr[5]" data-aos="flip-down" data-aos-duration="700" data-aos-easing="ease-in-out-cubic">{{
                  hourTime(true) }}</div>
              </div>
              <div class="t">
                <div v-if="t_arr[6]" data-aos="flip-down" data-aos-duration="700" data-aos-easing="ease-in-out-cubic">{{
                  hourTime() }}</div>
              </div>
              <p>小时</p>
            </div>
            <div>
              <div class="t">
                <div v-if="t_arr[1]" data-aos="flip-down" data-aos-duration="700" data-aos-easing="ease-in-out-cubic">{{
                  minTime(true) }}</div>
              </div>
              <div class="t">
                <div v-if="t_arr[2]" data-aos="flip-down" data-aos-duration="700" data-aos-easing="ease-in-out-cubic">{{
                  minTime()
                }}</div>
              </div>
              <p>分</p>
            </div>
            <div>
              <div class="t">
                <div v-if="t_arr[3]" data-aos="flip-down" data-aos-duration="700" data-aos-easing="ease-in-out-cubic">{{
                  secTime(true) }}</div>
              </div>
              <div class="t">
                <div v-if="t_arr[4]" data-aos="flip-down" data-aos-duration="700" data-aos-easing="ease-in-out-cubic">{{
                  secTime()
                }}</div>
              </div>
              <p>秒</p>
            </div>
          </div>
          <div class="wait" v-else>搅珠状态...</div>
        </div>
        <el-popover placement="top-start" trigger="hover">
          <div class="long_text_class" v-html="intro(lottery.lottery.code)"></div>
          <div class="game_intro" slot="reference">
            <!-- <img src="@assets/pc/game_intro.png"> -->
            游戏简介
          </div>
        </el-popover>
      </div>
    </div>
    <div class="routers container clearfix lottery-routers-swiper">
      <!-- <div class="title">
        <img :src="require('@assets/pc/' + router.name + '.png')" class="icon">
        <p>{{ router.name }}</p>
      </div> -->
      <div>
        <swiper ref="swiper" :options="swiperOptions" class="swiper">
          <swiper-slide class="router-list" v-for="(route, i) in routers_list" :key="route.type + i">
            <router-link :to="'/lottery/code/' + code + '/' + id + '/' + route.path">{{ route.name }}</router-link>
          </swiper-slide>

          <div class="prev" slot="button-prev">
            <div>
              <img src="@assets/pc/left.png" />
            </div>
          </div>
          <div class="next" slot="button-next">
            <div>
              <img src="@assets/pc/right.png" />
            </div>
            <span></span>
          </div>
        </swiper>
      </div>
    </div>
    <div class="table container">
      <router-view ref="lotteryTable"></router-view>
    </div>
  </div>
</template>

<script>
import 'aos/dist/aos.css'
import AOS from 'aos/dist/aos.js'
import Number from '@components/pc/Number'
import TableRouteWithType from '@plugins/routeType'
import { popover } from 'element-ui'

import { Swiper, SwiperSlide } from '@plugins/swiper'

export default {
  name: 'Lottery',
  components: {
    [Number.name]: Number,
    [popover.name]: popover,
    Swiper,
    SwiperSlide
  },
  props: {
    code: String,
    id: String
  },
  data() {
    return {
      first_sound: true,
      sound_t: null,
      is_tra: 0,
      animation_t: null,
      lottery: null,
      timer: null,
      timeout: null,
      requesting: false,
      t_arr: ['', true, true, true, true, true, true, true, true],
      swiperIndex: 0,
      swiperOptions: {
        navigation: {
          nextEl: '.next div',
          prevEl: '.prev div'
        },
        slidesPerView: 'auto',
        spaceBetween: 0,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
        freeMode: true,
        shortSwipes: true,
        on: {
          slideChange: this.swiperChange
        }
      }
    }
  },
  computed: {
    routers() {
      if (!this.lottery) return []
      return this.lottery.config.map(classes => {
        classes.list = classes.list.map(type => {
          type.path = TableRouteWithType(type.type)
          return type
        })
        return classes
      })
    },
    routers_list() {
      if (!this.lottery) return []
      const l_arr = this.lottery.config.map(classes => {
        classes.list = classes.list.map(type => {
          type.path = TableRouteWithType(type.type)
          return type
        })
        return classes
      })
      let arr = []
      for (let i in l_arr) {
        const name = l_arr[i]['name']
        if (name != '推介') {
          let list = l_arr[i]['list']
          list = list.map(item => {
            if (item.name.indexOf('五形') !== -1) item['name'] = item.name.replace('五形', '五行')
            return item
          })
          if (name == '路珠') {
            list = list.filter(item => {
              return item.type != 'number_road_bead' && item.type != 'sum_road_bead'
            })
          }
          arr = arr.concat(list)
        }
      }
      return arr
    },
    opencode() {
      if (!this.lottery) return null
      return this.lottery.lottery.opencode
    }
  },
  watch: {
    code: function (val, oldVal) {
      this.first_sound = true
      this.reqeustGetDetail(true)
    },
    id: function (val, oldVal) {
      this.reqeustGetDetail(true)
    },
    opencode(val, oldVal) {
      if (oldVal) {
        this.callChild()
      }
    }
  },
  mounted() {
    document.addEventListener('visibilitychange', this.visibilityChange)
    document.addEventListener('webkitvisibilitychange', this.visibilityChange)
    document.addEventListener('mozvisibilitychange', this.visibilityChange)
    AOS.init()
    this.reqeustGetDetail(true)
    // this.animation_start()
  },
  beforeDestroy() {
    this.clearTime()
    document.removeEventListener('visibilitychange', this.visibilityChange)
    document.removeEventListener('webkitvisibilitychange', this.visibilityChange)
    document.removeEventListener('mozvisibilitychange', this.visibilityChange)
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name === 'Lottery' && to.params.code === this.code && to.params.id === this.id && this.routers.length > 0) {
      var path = `/lottery/code/${this.code}/${this.id}`
      path = `${path}/${this.routers[0].list[0].path}`
      next({ path, replace: true })
    } else {
      next()
    }
  },
  destroyed() {
    clearInterval(this.animation_t)
    this.animation_t = null
  },
  methods: {
    visibilityChange() {
      this.clearTime()
      this.reqeustGetDetail(true)
    },
    clearTime() {
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
      if (this.timeout) {
        clearTimeout(this.timeout)
        this.timeout = null
      }
    },
    swiperChange(data) {
      const { activeIndex } = data
      this.swiperIndex = activeIndex
    },
    swiperPrev() {
      if (this.$refs['swiper']) {
        this.$refs.swiper.$swiper.slideTo(this.swiperIndex)
        this.swiperIndex -= 1
        this.swiperIndex = this.swiperIndex < 0 ? 0 : this.swiperIndex
      }
    },
    animation_start() {
      clearInterval(this.animation_t)
      this.animation_t = null
      this.animation_t = setInterval(() => {
        if (typeof this.lottery?.lottery.opencode_arr === 'object') {
          const len = this.lottery?.lottery.opencode_arr.length
          this.is_tra = this.is_tra < len ? this.is_tra + 1 : 0
        }
      }, 60)
    },
    play_sound() {
      if (this.first_sound) {
        this.first_sound = false
        return
      }
      if (this.sound_t) {
        return
      }
      this.$store.dispatch('home/set_sound', true)
      this.sound_t = setTimeout(() => {
        this.$store.dispatch('home/set_sound', false)
        clearTimeout(this.sound_t)
        this.sound_t = null
      }, 1000)
    },
    dayTime(bool, value) {
      const t = parseInt((value || this.lottery.lottery.next_time) / 86400 % 1000)
      if (bool) {
        return parseInt(t / 10)
      } else {
        return t % 10
      }
    },
    hourTime(bool, value) {
      const t = parseInt((value || this.lottery.lottery.next_time) / 3600 % 24)
      if (bool) {
        return parseInt(t / 10)
      } else {
        return t % 10
      }
    },
    minTime(bool, value) {
      const t = parseInt((value || this.lottery.lottery.next_time) / 60 % 60)
      if (bool) {
        return parseInt(t / 10)
      } else {
        return t % 10
      }
    },
    secTime(bool, value) {
      const t = (value || this.lottery.lottery.next_time) % 60
      if (bool) {
        return parseInt(t / 10)
      } else {
        return t % 10
      }
    },
    async out_time(i) {
      let t = await setTimeout(() => {
        this.t_arr.splice(i, 1, true)
        clearTimeout(t)
        t = null
      }, 50)
      return true
    },
    is_aos(i) {
      if (i === 1) {
        if (this.minTime(true) !== this.minTime(true, this.lottery.lottery.next_time + 1)) {
          this.t_arr.splice(i, 1, false)
          this.out_time(i)
        }
      }
      if (i === 2) {
        if (this.minTime(false) !== this.minTime(false, this.lottery.lottery.next_time + 1)) {
          this.t_arr.splice(i, 1, false)
          this.out_time(i)
        }
      }
      if (i === 3) {
        if (this.secTime(true) !== this.secTime(true, this.lottery.lottery.next_time + 1)) {
          this.t_arr.splice(i, 1, false)
          this.out_time(i)
        }
      }
      if (i === 4) {
        this.t_arr.splice(i, 1, false)
        this.out_time(i)
      }
      if (i === 5) {
        if (this.hourTime(true) !== this.hourTime(true, this.lottery.lottery.next_time + 1)) {
          this.t_arr.splice(i, 1, false)
          this.out_time(i)
        }
      }
      if (i === 6) {
        if (this.hourTime(false) !== this.hourTime(false, this.lottery.lottery.next_time + 1)) {
          this.t_arr.splice(i, 1, false)
          this.out_time(i)
        }
      }
      if (i === 7) {
        if (this.dayTime(true) !== this.dayTime(true, this.lottery.lottery.next_time + 1)) {
          this.t_arr.splice(i, 1, false)
          this.out_time(i)
        }
      }
      if (i === 8) {
        if (this.dayTime(false) !== this.dayTime(false, this.lottery.lottery.next_time + 1)) {
          this.t_arr.splice(i, 1, false)
          this.out_time(i)
        }
      }
    },
    callChild() {
      this.$refs.lotteryTable.refresh()
    },
    pushLive() {
      this.$router.push(`/lottery/live/${this.code}/${this.id}`)
    },
    routerReplace() {
      if (this.$route.name === 'Lottery' && this.routers.length > 0) {
        const path = `/lottery/code/${this.code}/${this.id}`
        this.$router.replace(`${path}/${this.routers[0].list[0].path}`)
      }
    },
    reqeustGetDetail(showLoading) {
      if (this.requesting) return
      this.requesting = true
      if (showLoading) {
        this.$loading.show()
      }
      this.$api.detail(this.code, this.id).then(res => {
        this.requesting = false
        this.lottery = res.data
        if (this.$route.path.indexOf('code') !== -1) {
          if (this.lottery?.lottery.next_time > 0) {
            this.play_sound()
          } else {
            this.first_sound = false
          }
        }
        this.routerReplace()
        this.$loading.hide()
        this.startCountDown()
      }).catch(err => {
        this.requesting = false

        this.$loading.hide()
        this.$message.error(err)
      })
    },
    startCountDown() {
      if (this.lottery.lottery.next_time > 0) {
        if (this.timer) {
          clearInterval(this.timer)
          this.timer = null
        }
        this.timer = setInterval(() => {
          this.lottery.lottery.next_time--
          this.is_aos(1)
          this.is_aos(2)
          this.is_aos(3)
          this.is_aos(4)
          this.is_aos(5)
          this.is_aos(6)
          this.is_aos(7)
          this.is_aos(8)
          if (this.lottery.lottery.next_time === 0) {
            clearInterval(this.timer)
            this.timer = null
            this.reqeustGetDetail(false)
          }
        }, 1000)
      } else {
        this.timeout = setTimeout(() => {
          clearTimeout(this.timeout)
          this.timeout = null
          this.reqeustGetDetail(false)
        }, 3000)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@scss/pc/variables';

.n-lottery {
  .full_header {
    padding-top: 30px;
    height: 130px;
    border-bottom: 1px solid #ddd;
  }

  .header {
    display: flex;
    align-items: center;
    height: 100%;
    white-space: nowrap;
    background-color: #fff;

    >.lottery {
      flex: 1;
      display: flex;
      align-items: center;

      >.icon {
        margin-left: 12px;
        width: 84px;
        height: 84px;
        object-fit: contain;
      }

      >.content {
        margin-left: 14px;
        display: flex;
        align-items: center;

        >.text {
          display: flex;
          align-items: center;
        }

        p {
          font-size: 14px;
          color: #A5A5A5;

          &+p {
            margin-left: 16px;
          }
        }

        .lot_title {
          margin-right: 20px;
          font-size: 26px;
          color: #222;
          font-weight: 400;
          line-height: normal;
        }

        >.numbers {
          >div {
            display: flex;
            align-items: center;
          }
        }

        >.detail {
          margin-top: 5px;
          font-size: 14px;
          color: rgba($color: #000000, $alpha: 0.5);

          span {
            color: #0091ff;
          }
        }
      }
    }

    >.count-down {
      min-width: 414px;
      padding-left: 16px;

      >.lot_text {
        font-size: 16px;
        color: #222;
        display: flex;
        flex-direction: row;
        align-items: center;

        >div {
          display: flex;
          align-items: center;

          >img {
            margin-right: 8px;
            width: 24px;
            height: auto;
          }
        }
      }

      >.wait {
        margin-top: 6px;
        width: 236px;
        color: #FA3E3E;
        font-weight: 500;
        font-size: 18px;
        text-align: left;
      }

      >.text {
        display: flex;
        align-items: center;

        >p {
          font-size: 14px;
          color: rgba($color: #000000, $alpha: 0.8);

          span {
            margin-left: 3px;
            margin-right: 3px;
            color: #ff7300;
          }
        }
      }

      >.timer {
        padding-right: 33px;
        min-width: 193px;
        // background-image: url("~@assets/pc/time_back_big.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        box-sizing: content-box;
        display: flex;

        div {
          display: flex;
          align-items: center;
        }

        .t {
          min-width: 36px;
          font-size: 46px;
          color: #444545;
          text-align: center;
          line-height: normal;
        }

        p {
          margin-top: 10px;
          margin-right: 10px;
          font-size: 23px;
          text-align: center;
          color: #444545;
        }
      }
    }

    .game_intro {
      margin-left: 12px;
      // margin-right: 16px;
      width: 100px;
      height: 40px;
      border-radius: 6px;
      text-align: right;
      color: #333;
      font-size: 15px;
      font-weight: bold;
      // background-color: #FF6161;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      >img {
        margin-right: 10px;
        width: auto;
        height: 20px;
      }
    }
  }

  >.routers {
    margin-bottom: 30px;
    background-color: #fff;

    >div {
      width: 100%;
      box-sizing: border-box;
      position: relative;
    }

    .swiper {
      padding: 0 20px;
      width: 100%;
      height: 48px;
      box-sizing: border-box;
      overflow: hidden;
      position: relative;
      display: flex;
      align-items: center;

      &::before {
        content: '';
        display: block;
        width: 20px;
        height: 100%;
        background-color: #fff;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
      }

      &::after {
        content: '';
        display: block;
        width: 20px;
        height: 100%;
        background-color: #fff;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 100;
      }

      .prev,
      .next {
        width: 20px;
        height: 100%;
        cursor: pointer;
        position: absolute;
        top: 0;
        z-index: 998;

        div {
          display: none;
          width: 100%;
          height: 100%;

          >img {
            width: 8px;
            height: auto;
          }
        }
      }

      .prev:hover,
      .next:hover {
        >div {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .prev {
        left: 0px;
      }

      .next {
        right: 0px;
      }
    }

    .router-list {
      flex-shrink: 0;
      padding: 0 12px;
      width: auto;
      height: 28px;

      &:nth-child(10n) {
        margin-right: 0;
      }

      a {
        width: 100%;
        height: 100%;
        color: #333;
        font-size: 14px;
        text-decoration-line: none;
        // background: #F8F8F8;
        border-radius: 6px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        &.router-link-exact-active {
          font-weight: bold;
          color: #FA3E3E;
        }
      }
    }
  }

  >.table {
    padding-bottom: 40px;
    overflow: hidden;

    >div {
      background-color: #fff;
      padding-bottom: 22px;
    }
  }
}

.transparent60 {
  opacity: 0.6;
  filter: alpha(opacity=60);
}

.transparent100 {
  opacity: 1;
  filter: alpha(opacity=100);
}
</style>

<style lang="scss">
// .el-tooltip__popper.is-dark {
//   background-color: #FF6161 !important;
// }
// .el-tooltip__popper.is-dark {
//   background: #FF6161 !important;
// }
// .popper__arrow::after{
//   border-top-color:#FF6161 !important;
// }
// .popper__arrow {
//   border-top-color:#FF6161 !important;
// }

.lottery-routers-swiper .swiper-wrapper {
  display: flex;
  align-items: center;
}
</style>
